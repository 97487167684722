<template>
	<div class="homePage">
		<Navbar showType="1" class="homePageHeader" />
		<div class="content">
			<template v-if="adminMenuList?.length">
				<header>管理员应用</header>
				<div class="itemWrap">
					<div class="item" v-for="(item, inx) in adminMenuList" :key="inx">
						<div
							@click="() => handleIcon(item)"
							:style="`width: 60px; height: 60px; background: ${item.background}; border-radius: 50%; margin-bottom: 10px;cursor:pointer`"
						>
							<svg-icon style="width: 60px; padding: 14px; height: 100%" :icon-class="item.icon"></svg-icon>
						</div>
						<span>{{ item.text }}</span>
					</div>
				</div>
			</template>
			<template v-if="staffMenuList?.length">
				<header>员工应用</header>
				<div class="itemWrap">
					<div @click="() => handleIcon(item)" class="item" v-for="(item, inx) in staffMenuList" :key="inx">
						<div :style="`width: 60px; height: 60px; border-radius: 50%; margin-bottom: 10px;cursor:pointer`">
							<svg-icon style="width: 60px; height: 100%" :icon-class="item.icon"></svg-icon>
						</div>
						<span>{{ item.text }}</span>
					</div>
				</div>
			</template>
			<template v-if="!staffMenuList?.length && !adminMenuList?.length">
				<empty />
			</template>
		</div>
	</div>
</template>

<script>
import { Loading } from 'element-ui';
import { Navbar } from '../../layout/components';
import empty from '@/views/components/components-empty/index.vue';

export default {
	name: 'homePage',
	components: { Navbar, empty },

	data() {
		return {
			adminList: [
				{ text: '基础人事', icon: 'organizingpersonnel', background: '#ff9a43', url: '/humanaffairs/personnelInformation' },
				{ text: '做对事', icon: 'index', background: '#49bdc2', url: '/indicatorsOfLibrary/list' },
				{ text: '用对人', icon: 'talentassessment', background: '#ff9a43', url: '/useTalentCorrectly/talentMap' },
				{ text: '分好钱', icon: '薪酬激励@1x', background: '#ff9a43', url: '/remuneration/salaryRangeSetting' },
				{ text: '审批管理', icon: '审批 @1x', background: '#ff9a43', url: '/approval/index' },
				{ text: '权限管理', icon: 'shezhi', background: '#49bdc2', url: '/system-config/role' },
				{ text: '系统管理', icon: '报表@1x', background: '#49bdc2', url: '/companys/companyInformationSet' }
			],
			staffList: [
				{ text: '审批', icon: 'aduit', background: '#ff9a43', url: '/staff/approval' },
				{ text: '人力规划', icon: 'plan', background: '#ff9a43', url: '/staff/talent_planning' },
				{ text: '人才评估', icon: 'pipei', background: '#ff9a43', url: '/staff/match_position' },
				{ text: '招聘调配', icon: 'zhaopin', background: '#ff9a43', url: '/staff/recruit' },
				{ text: '绩效', icon: 'jixiao', background: '#ff9a43', url: '/staff/achievements' },
				{ text: '薪酬', icon: 'xinchou', background: '#ff9a43', url: '/staff/momey' },
				{ text: '教育培训', icon: 'train', background: '#ff9a43', url: '/staff/trainMange' },
				{ text: '核心人力', icon: 'hexin', background: '#ff9a43', url: '/staff/corehuman' },
				{ text: '抄送信息', icon: 'ccinformation', background: '#ff9a43', url: '/staff/cc' },
				{ text: '信息', icon: 'info', background: '#ff9a43', url: '/staff/info' }
			],
			adminMenuList: [],
			staffMenuList: []
		};
	},
	computed: {
		menusPermission() {
			const { menusPermission = [] } = this.$store.state.app;

			return menusPermission;
		}
	},
	mounted() {
		this.getApproalList();
	},

	methods: {
		getApproalList() {
			const loading = Loading.service({ target: '.homePage' });

			this.$axios
				.post('/v1/menu/listByRoleId', { request: {} })
				.then((res) => {
					loading.close();
					if (res.code) {
						return;
					}
					const { adminMenuList, staffMenuList } = res;

					this.adminMenuList = adminMenuList?.map(({ menuName, id, roleId, topId }) => ({
						...this.adminList.find(({ text }) => menuName === text),
						id,
						roleId,
						topId
					}));
					this.staffMenuList = staffMenuList
						?.filter(({ isShow }) => isShow === 1)
						?.map(({ menuName, id, roleId, topId }) => ({
							...this.staffList.find(({ text }) => menuName === text),
							id,
							roleId,
							topId
						}));
				})
				.catch(() => {
					loading.close();
				});
		},
		// getSubMenuList(id, roleId) {
		// 	return this.$axios.post('/v1/menu/listByMenuId', { request: { id, roleId } }).then((res) => {
		// 		if (res.code) {
		// 			return [];
		// 		}
		// 		return res;
		// 	});
		// },
		async handleIcon(item) {
			const data = this.menusPermission.filter(({ topId, path, menuType }) => item.topId === topId && path && menuType === 'C');

			if (data?.length) {
				if (data[0]?.moduleType === 1) {
					this.$store.dispatch('app/setMenuItemTopId', item.topId);
				}
				const obj = data.sort((a, b) => a.orderNum - b.orderNum)[0] || {};

				this.$router.push({ path: obj.path });
			} else {
				this.$message.error('无法访问，请联系管理员');
			}
		}
	}
};
</script>
<style lang="scss">
.homePageHeader {
	&.navbar {
		display: inline-flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		height: 68px;
		z-index: 1;
		// background: #4a8df0;
		// color: #fff;
		// :deep(.right-menu) {
		// 	line-height: 68px !important;
		// }
	}
}
</style>
<style lang="scss" scoped>
.homePage {
	background: #fff;
	.content {
		// display: inline-flex;
		min-height: calc(100vh - 60px);
		width: 100%;
		padding: 20px 100px;
		header {
			color: #999;
			margin-top: 30px;
			height: 30px;
			line-height: 30px;
			font-size: 18px;
		}
		.itemWrap {
			width: 100%;
			display: inline-flex;
			margin: 20px;
			margin-bottom: 40px;
			flex-wrap: wrap;
			.item {
				width: 70px;
				display: inline-flex;
				margin-right: 60px;
				height: 120px;
				justify-content: center;
				align-items: center;
				flex-direction: column;
			}
		}
	}
}
</style>
